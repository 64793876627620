const OPENED_CLASS = "opened"
const HEADER_WITH_SHADOW_CLASS = "shadow"

// Important: only works for the first menu button found. Will ignore other occurences.
const menuBtn = document.getElementsByClassName("navbar__menu__btn")[0];
const icon = menuBtn.getElementsByClassName("icon__menu")[0];
const menu = document.getElementsByClassName("navbar__menu")[0];
menuBtn.onclick = (event) => {
  event.stopImmediatePropagation();

  icon.classList.toggle(OPENED_CLASS);
  menu.classList.toggle(OPENED_CLASS);
}

document.addEventListener("click", (event) => {
  const clickedInside = menu.contains(event.target);

  if (!clickedInside && icon.classList.contains(OPENED_CLASS)) {
    icon.classList.remove(OPENED_CLASS);
    menu.classList.remove(OPENED_CLASS);
  }
});

const header = document.getElementsByTagName("header")[0];
window.addEventListener("scroll", function() {
  const isAtTop = window.scrollY === 0;
  const hasClass = header.classList.contains(HEADER_WITH_SHADOW_CLASS);
  if (isAtTop && hasClass) {
    header.classList.remove(HEADER_WITH_SHADOW_CLASS);
  }
  else if (!isAtTop && !hasClass) {
    header.classList.add(HEADER_WITH_SHADOW_CLASS);
  }
});

const userBtn = document.getElementsByClassName("user-btn")[0];
if (userBtn) {
  const userMenu = userBtn.parentElement.getElementsByTagName("ul")[0];
  userBtn.addEventListener("click", () => {
    userMenu.classList.toggle("show");
  })
}